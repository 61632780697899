let init = ()=>{
    let currentElement = null;
    let titleDiv = $("<div>").addClass("title_div");
    //$("[title]").hover(function(e){
    $(document).on('mouseenter', '[help]', function(e){
        currentElement = $(this);
        titleDiv.html( currentElement.attr("help") );
        titleDiv.css({top: e.clientY, left: e.clientX});
        titleDiv.appendTo($("body"));
    }).on('mouseleave', '[help]', function(e){ currentElement=null;titleDiv.detach() })
    .on('mousemove', e=>{
        if (currentElement){
            if ($('body').find(currentElement).length){
                let x = e.clientX, y = e.clientY;
                let w = titleDiv.width(), h = titleDiv.height();
                if (x+w>=document.body.clientWidth) x -= w;
                if (y+h>=document.body.clientHeight) y -= h;
                if (x<0) x = 0;
                if (y<0) y = 0;
                titleDiv.css({top: y, left: x});
            }
            else if ($('body').find(titleDiv).length) {
                titleDiv.detach();
                currentElement = null;
            }
        }
    });
}

// Dirty timer to wait for dynamic population (e.g. from experiment_model)
document.addEventListener('DOMContentLoaded',()=>setTimeout(init, 100));
